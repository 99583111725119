.UnitEdit .el-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.UnitEdit .el-form .el-form-item {
  width: 100%;
  margin-bottom: 22px;
}
.UnitEdit .el-upload {
  height: 180px !important;
}
.UnitEdit .upload-btn {
  height: 180px;
}
